import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2018-03-01T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/reading/seveneves-izzy.jpg",
        "alt": "Izzy"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/reading/seveneves-cradle.png",
        "alt": "The cradle"
      }}></img></p>
    <a href="http://www.stanleyvonmedvey.com/art/4m71t70ggx3r3xfy86yz5mczknfxa9"><img alt="Seveneves - meeting in the banana" width="49.8%" src="/reading/seveneves-banana.jpg" /></a>
    <a href="https://www.deviantart.com/bagtaggar/art/Tekla-569099791"><img alt="Seveneves - Tekla in her suit" width="50.2%" src="/reading/seveneves-tekla.jpg" /></a>
    <a href="https://blenderartists.org/t/h-a-n-glider-blenderguru-future-thech-comptetiton/652708"><img alt="Seveneves - glider" width="100%" src="/reading/seveneves-glider.jpg" /></a>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`Seveneves`}</strong></li>
      <li parentName="ul">{`Author: Neal Stephenson`}</li>
      <li parentName="ul">{`Genre: Hard science fiction`}</li>
    </ul>
    <p>{`My rating: ★★★★★ `}</p>
    <p>{`This is one of my favorite science-fiction books of all time. It's starts in the not-to-distant future, is based on real science so feels believable, and then jumps farther into the future, really capturing the imagination. It also has strong women at the center of the novel, venturing into ethics and what it means to be human. It's long but I didn't want it to end, I wanted the next chapture. There were plans to make it into a movie a few years ago, hope that becomes a reality.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      